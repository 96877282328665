
import { Options, Vue } from 'vue-class-component'
import orderHistory from '@/components/Memberportal/OrderHistory/index.vue'

@Options({
  components: {
    orderHistory
  }
})
export default class OrderHistory extends Vue {}
